
import ManagementMixin from '@/mixins/ManagementMixin';
import CommonMixin from '@/mixins/CommonMixin';

export default {
  mixins: [CommonMixin, ManagementMixin],
  data() {
    return {
      data: {}
    };
  },
  async created() {
    let key = this.$route.params.id;
    const data = await this.$repo.store.getImportResult(key);
    if (data == null) {
      this.close();
    }
    this.data = data;
  },
  methods: {
    close() {
      this.$router.push({
        name: 'management:store'
      });
    }
  }
};
